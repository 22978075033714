import React from 'react'
import {graphql, Link} from 'gatsby'
import {ModalRoutingContext} from 'gatsby-plugin-modal-routing'
import Header from "../components/header";
import Layout from "../components/layout";
import Helmet from "react-helmet";
import {navigate} from 'gatsby';


export default class TeamDetails extends React.Component {
    state = {
        hover: true,
        hoverReisen: false,
        hoverFreizeit: false,
        hoverChatbot: false,
        hoverPaixon: false,
    };

    constructor(props) {
        super(props);
        this.escFunction = this.escFunction.bind(this);
    }

    escFunction(event) {
        if (event.keyCode === 27) {
            return navigate('/team')
        }
    }

    componentDidMount() {
        document.addEventListener("keydown", this.escFunction, false);
    }

    hoverNo = property => {
        this.setState({[property]: this.state[property] = true});
        this.setState({hover: this.state.hover = false})
    };

    hoverOff = property => {
        this.setState({[property]: this.state[property] = false});
        this.setState({hover: this.state.hover = true})
    };

    render() {

        let {hover, hoverReisen, hoverFreizeit, hoverChatbot, hoverPaixon} = this.state;
        let member = this.props.data.strapiTeams;
        let degrees = member.degree.split('/');
        let teaching;
        if (member.teaching_jobs !== null) {
            teaching = member.teaching_jobs.split('/');
        }

        return (
            <ModalRoutingContext.Consumer>
                {({modal, closeTo}) => (
                    <div>
                        <Helmet>
                            <meta charSet="utf-8"/>
                            <title>Team | Paixon</title>
                            <meta name="description" content={member.meta_description}/>
                        </Helmet>
                        {modal ? (
                            <div>
                                <a href={'/team'} className="exitButton" />
                                <div className="teamDetails">
                                    <div className="teamDetailsInfo">
                                        <div className="person-title">
                                            <h1>{member.name}</h1>
                                            <span className="role">{member.role}</span>
                                            <br/>
                                            {degrees.map(dereess =>
                                                <span className="degree">{dereess}</span>
                                            )}
                                            <br/>
                                            <p className="shortInfo">{member.characteristics}</p>
                                        </div>
                                        <div className="person-info">
                                            <p className="description">{member.description}</p>
                                            {member.teaching_jobs && (
                                                <div className="teaching">
                                                    <h3>Bildungsaufträge</h3>
                                                    <ul className="teachingList">
                                                        {teaching.map(jobs =>
                                                            <li>{jobs}</li>
                                                        )}
                                                    </ul>
                                                </div>
                                            )}

                                            <h3>Kontakt</h3>
                                            {member.linkedin &&
                                            <a
                                                className="linkedin"
                                                href={member.linkedin}
                                                title={`Linkedin ${member.name}`}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <span className="fa fa-linkedin"></span>Linkedin (englischer
                                                Lebenslauf)

                                                <br/>
                                            </a>}
                                            <a href={`mailto:${member.email}`} title={member.email} className="email">
                                                <span className="fa fa-envelope"></span>
                                                {member.email}
                                            </a>
                                            <p>
                                                {member.phone && <span className="fa fa-phone"></span>}
                                                <a href={`tel:${member.phone}`} title={member.phone}>{member.phone}</a>
                                            </p>
                                            <br/>
                                        </div>
                                    </div>
                                    <div className="teamDetailsPhotos">
                                        <div className='teamDetailsimage' title={`Foto ${member.name}`}
                                             style={{backgroundImage: `url(${member.portraitPhoto.publicURL})`}}></div>
                                        <div className='teamDetailsimage' title={`Foto der Arbeit ${member.name}`}
                                             style={{backgroundImage: `url(${member.hobbyPhoto.publicURL})`}}></div>
                                        <div className='teamDetailsimage' title={`Foto Hobby ${member.name}`}
                                             style={{backgroundImage: `url(${member.workPhoto.publicURL})`}}></div>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className="normalPage">
                                <a href={'/team'} className="exitButton" />
                                <div className="teamDetails">
                                    <div className="teamDetailsInfo">
                                        <div className="person-title">
                                            <h1>{member.name}</h1>
                                            <span className="role">{member.role}</span>
                                            <br/>
                                            <span className="degree">{member.degree}</span>
                                            <br/>
                                            <strong className="shortInfo">{member.characteristics}</strong>
                                        </div>
                                        <div className="person-info">
                                            <p className="description">{member.description}</p>
                                            {member.teaching_jobs && (
                                                <div className="teaching">
                                                    <h3>Bildungsaufträge</h3>
                                                    <ul className="teachingList">
                                                        {teaching.map(jobs =>
                                                            <li>{jobs}</li>
                                                        )}
                                                    </ul>
                                                </div>
                                            )}

                                            <h3>Kontakt</h3>
                                            {member.linkedin &&
                                            <a
                                                className="linkedin"
                                                href={member.linkedin}
                                                title={`Linkedin ${member.name}`}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <span className="fa fa-linkedin"></span>Linkedin (englischer
                                                Lebenslauf)

                                                <br/>
                                            </a>}

                                            <br/>
                                            <a href={`mailto:${member.email}`} title={member.email} className="email">
                                                <span className="fa fa-envelope"></span>
                                                {member.email}
                                            </a>
                                            <p>
                                                {member.phone && <span className="fa fa-phone"></span>}
                                                <a href={`tel:${member.phone}`} title={member.phone}>{member.phone}</a>
                                            </p>
                                            <br/>
                                        </div>
                                    </div>
                                    <div className="teamDetailsPhotos">
                                        <div className='teamDetailsimage'
                                             style={{backgroundImage: `url(${member.portraitPhoto.publicURL})`}}></div>
                                        <div className='teamDetailsimage'
                                             style={{backgroundImage: `url(${member.hobbyPhoto.publicURL})`}}></div>
                                        <div className='teamDetailsimage'
                                             style={{backgroundImage: `url(${member.workPhoto.publicURL})`}}></div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>

                )}
            </ModalRoutingContext.Consumer>
        )
    }
};

export const pageQuery = graphql` 
query TeamDetails($teamUrl: String!) {
    strapiTeams(url: { eq: $teamUrl }) { 
        id
        name
        email
        meta_description
        status
        degree
        teaching_jobs
        characteristics
        portraitPhoto {
            publicURL
        }
        description
        linkedin
        phone
        order
        url
        hobbyPhoto {
            publicURL
        }
        workPhoto {
            publicURL
        }
        role
    }
    allStrapiTeamPages {
        edges {
            node {
                content
                slogan
                Photo {
                    publicURL
                }
            }
        }
    }
}`
